export const TIMEOUT = 20000;

export const DEFAULT_VIQEO_PLAYER_ID = 2349;
export const DEFAULT_VIQEO_PROFILE_ID = 10099;

// Google
export const DISABLE_GOOGLE_ADS = false;
export const GOOGLE_ADSENSE_AD_CLIENT = 'ca-pub-4306008583934527';
export const GOOGLE_ADSENSE_AD_SLOT = '1730685615';

export const GOOGLE_CX = '001202538024458911449:tmn7mxctxjo';

export const TEST_TITLE_KEY_PREFIX = 'test-title-for-';

export const SEND_TYPO_DATA = 'SEND_TYPO_DATA';

// * UI
export const SHOW_NAV = 'SHOW_NAV';
export const HIDE_NAV = 'HIDE_NAV';
export const SHOW_SAFARI_PUSH_DIALOG = 'SHOW_SAFARI_PUSH_DIALOG';
export const HIDE_SAFARI_PUSH_DIALOG = 'HIDE_SAFARI_PUSH_DIALOG';

export const ADD_JOB = 'ADD_JOB';
export const CLEAR_ALL_JOBS = 'CLEAR_ALL_JOBS';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_LOCATION = 'FETCH_USER_LOCATION';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_IS_USER_LOADING = 'UPDATE_IS_USER_LOADING';
export const UPDATE_USER_PROPERTY = 'UPDATE_USER_PROPERTY';
export const ADD_INTEGER_ID_USER = 'ADD_INTEGER_ID_USER';
export const SET_AUTH_METHODS_RESTRICTED = 'SET_AUTH_METHODS_RESTRICTED';
export const REMOVE_INTEGER_ID_USER = 'REMOVE_INTEGER_ID_USER';

export const SET_USER_RT_ACTION = 'SET_USER_RT_ACTION';
export const SET_USER_RT_MUTATION = 'SET_USER_RT_MUTATION';
export const GET_IS_SEND_REFRESH_TOKEN = 'GET_IS_SEND_REFRESH_TOKEN';

export const PATCH_USER_PROPERTY = 'PATCH_USER_PROPERTY';
export const DISABLE_SOCIAL_ACCOUNT = 'DISABLE_SOCIAL_ACCOUNT';
export const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT';

export const CLEAR_USER = 'CLEAR_USER';
export const CLEAR_USER_RELATED_DATA = 'CLEAR_USER_RELATED_DATA';

export const GET_AND_SET_FAVORITES = 'GET_AND_SET_FAVORITES';
export const UPDATE_FAVORITES = 'UPDATE_FAVORITES';
export const ADD_FAVORITE = 'ADD_FAVORITE';
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';

export const UPDATE_COMMENT_COUNTS = 'UPDATE_COMMENT_COUNTS';
export const UPDATE_VIEWS_COUNTS = 'UPDATE_VIEWS_COUNTS';
export const UPDATE_WORDPRESS_AUTHENTICATION = 'UPDATE_WORDPRESS_AUTHENTICATION';
export const SUBSCRIBE_TO_WEB_PUSH_NOTIFICATIONS = 'SUBSCRIBE_TO_WEB_PUSH_NOTIFICATIONS';
export const UNSUBSCRIBE_FROM_WEB_PUSH_NOTIFICATIONS = 'UNSUBSCRIBE_FROM_WEB_PUSH_NOTIFICATIONS';
export const UPDATE_IS_HEAD_SCRIPTS_LOADED = 'UPDATE_IS_HEAD_SCRIPTS_LOADED';

// * articles
export const FETCH_COMMENTS_COUNT = 'FETCH_COMMENTS_COUNT';
export const FETCH_VIEWS_COUNT = 'FETCH_VIEWS_COUNT';
export const SET_CURRENT_TITLE = 'SET_CURRENT_TITLE';
export const UPDATE_CURRENT_TITLE = 'UPDATE_CURRENT_TITLE';

// * pages data
export const ADD_ARTICLE_DATA = 'ADD_ARTICLE_DATA';
export const ADD_ARCHIVE_DATA = 'ADD_ARCHIVE_DATA';
export const UPDATE_CURRENT_ARTICLES = 'UPDATE_CURRENT_ARTICLES';

// * typos
export const ADD_TYPO_TEXT_AND_SHOW_FORM = 'ADD_TYPO_TEXT_AND_SHOW_FORM';
export const UPDATE_TYPO_COMMENT = 'UPDATE_TYPO_COMMENT';
export const HIDE_TYPO_FORM = 'HIDE_TYPO_FORM';

export const ADD_FOLDER = 'ADD_FOLDER';
export const RENAME_FOLDER = 'RENAME_FOLDER';
export const GET_FOLDERS = 'GET_FOLDERS';
export const SET_FOLDERS = 'SET_FOLDERS';
export const CLEAR_FOLDERS = 'CLEAR_FOLDERS';
export const DELETE_FOLDER = 'DELETE_FOLDER';

// * menus
export const FETCH_MENUS_IF_NEEDED = 'FETCH_MENUS_IF_NEEDED';
export const FETCH_REGULAR_MENUS = 'FETCH_REGULAR_MENUS';
export const FETCH_HEALTH_MENUS = 'FETCH_HEALTH_MENUS';
export const UPDATE_ALL_MENUS = 'UPDATE_ALL_MENUS';
export const UPDATE_REGULAR_MENU_LOADED = 'UPDATE_REGULAR_MENU_LOADED';
export const UPDATE_HEALTH_MENU_LOADED = 'UPDATE_HEALTH_MENU_LOADED';

// * Widgets
export const FETCH_WIDGETS = 'FETCH_WIDGETS';
export const FETCH_RECIPE_WIDGETS = 'FETCH_RECIPE_WIDGETS';
export const UPDATE_SIDEBAR_WIDGETS = 'UPDATE_SIDEBAR_WIDGETS';
export const UPDATE_FOOTER_WIDGETS = 'UPDATE_FOOTER_WIDGETS';
export const UPDATE_WIDGETS_BELOW_HEADER = 'UPDATE_WIDGETS_BELOW_HEADER';
export const UPDATE_WIDGETS_ABOVE_HEADER = 'UPDATE_WIDGETS_ABOVE_HEADER';
export const UPDATE_WIDGETS_BETWEEN_POSTS = 'UPDATE_WIDGETS_BETWEEN_POSTS';
export const UPDATE_WIDGETS_AFTER_EXCERPT = 'UPDATE_WIDGETS_AFTER_EXCERPT';
export const UPDATE_WIDGETS_ABOVE_COMMENTS = 'UPDATE_WIDGETS_ABOVE_COMMENTS';
export const UPDATE_WIDGETS_BELOW_COMMENTS = 'UPDATE_WIDGETS_BELOW_COMMENTS';
export const UPDATE_WIDGETS_RECIPE_SIDEBAR = 'UPDATE_WIDGETS_RECIPE_SIDEBAR';
export const UPDATE_WIDGETS_RECIPES_SIDEBAR = 'UPDATE_WIDGETS_RECIPES_SIDEBAR';
export const UPDATE_WIDGETS_BETWEEN_RECIPES = 'UPDATE_WIDGETS_BETWEEN_RECIPES';
// * Recipes
export const UPDATE_WIDGETS_ABOVE_RECIPE_COMMENTS = 'UPDATE_WIDGETS_ABOVE_RECIPE_COMMENTS';
export const UPDATE_WIDGETS_BELOW_RECIPE_COMMENTS = 'UPDATE_WIDGETS_BELOW_RECIPE_COMMENTS';
export const UPDATE_WIDGETS_ABOVE_RECIPE_HEADER = 'UPDATE_WIDGETS_ABOVE_RECIPE_HEADER';
export const UPDATE_WIDGETS_BELOW_RECIPE_HEADER = 'UPDATE_WIDGETS_BELOW_RECIPE_HEADER';
export const UPDATE_WIDGETS_INSIDE_RECIPE = 'UPDATE_WIDGETS_INSIDE_RECIPE';
export const UPDATE_WIDGETS_LEFT_SIDEBAR = 'UPDATE_WIDGETS_LEFT_SIDEBAR';
export const UPDATE_POST_WIDGETS = 'UPDATE_POST_WIDGETS';

// * Health
export const UPDATE_WIDGETS_HEALTH_MAIN = 'UPDATE_WIDGETS_HEALTH_MAIN';
export const UPDATE_WIDGETS_HEALTH_SIDEBAR = 'UPDATE_WIDGETS_HEALTH_SIDEBAR';
export const UPDATE_WIDGETS_HEALTH_ABOVE_MENU = 'UPDATE_WIDGETS_HEALTH_ABOVE_MENU';
export const UPDATE_WIDGETS_HEALTH_BELOW_MENU = 'UPDATE_WIDGETS_HEALTH_BELOW_MENU';
export const UPDATE_WIDGETS_HEALTH_ABOVE_COMMENTS = 'UPDATE_WIDGETS_HEALTH_ABOVE_COMMENTS';
export const UPDATE_WIDGETS_HEALTH_BELOW_COMMENTS = 'UPDATE_WIDGETS_HEALTH_BELOW_COMMENTS';
export const UPDATE_WIDGETS_HEALTH_FOOTER = 'UPDATE_WIDGETS_HEALTH_FOOTER';
export const UPDATE_WIDGETS_HEALTH_AFTER_FIRST_PARAGRAPH = 'UPDATE_WIDGETS_HEALTH_AFTER_FIRST_PARAGRAPH';
export const UPDATE_WIDGETS_HEALTH_POST_DYNAMICS = 'UPDATE_WIDGETS_HEALTH_POST_DYNAMICS';
export const UPDATE_WIDGETS_HEALTH_SCRIPTS = 'UPDATE_WIDGETS_HEALTH_SCRIPTS';

export const FETCH_HEALTH_WIDGETS = 'FETCH_HEALTH_WIDGETS';

// * Action types
export const GET_AND_SET_COMMENTS_COUNT = 'GET_AND_SET_COMMENTS_COUNT';
export const FETCH_LEFT_SIDEBAR_ARTICLES = 'FETCH_LEFT_SIDEBAR_ARTICLES';
export const GET_AND_SET_VIEWS_COUNTS = 'GET_AND_SET_VIEWS_COUNTS';
export const UPDATE_WIDGETS_POST_DYNAMIC = 'UPDATE_WIDGETS_POST_DYNAMIC';
export const UPDATE_WIDGETS_AMP_AFTER_FIRST_PARAGRAPH = 'UPDATE_WIDGETS_AMP_AFTER_FIRST_PARAGRAPH';

// * ab titles data
export const ADD_AB_TITLE_DATA = 'ADD_AB_TITLE_DATA';
export const GET_AB_TITLE_BY_ARTICLE = 'GET_AB_TITLE_BY_ARTICLE';
export const ADD_AB_TITLE_EVENT = 'ADD_AB_TITLE_EVENT';
export const SEND_AB_TITLE_EVENTS = 'SEND_AB_TITLE_EVENTS';
export const REMOVE_AB_TITLE_EVENTS = 'REMOVE_AB_TITLE_EVENTS';
export const SET_TRUE_ADDED_UNLOAD_HANDLER = 'SET_TRUE_ADDED_UNLOAD_HANDLER';
export const REMOVE_AB_TITLES = 'REMOVE_AB_TITLES';

// seo
export const ADD_SEO_BY_PATH = 'ADD_SEO_BY_PATH';

// * adfox params
export const ADD_ADFOX_PARAMS_BY_PATH = 'ADD_ADFOX_PARAMS_BY_PATH';
export const ADD_ADFOX_IP_V6 = 'ADD_ADFOX_IP_V6';

// * reactions
export const FETCH_REACTIONS = 'FETCH_REACTIONS';
export const RE_FETCH_REACTIONS = 'RE_FETCH_REACTIONS';
export const UPDATE_REACTIONS = 'ADD_REACTIONS';
export const UPDATE_LIKE = 'UPDATE_LIKE';
export const PRE_UPDATE_REACTION = 'PRE_UPDATE_REACTION';
export const SET_IS_PENDING_REACTION = 'SET_IS_PENDING_REACTION';
export const UPDATE_REACTION = 'UPDATE_REACTION';
export const UPDATE_DISLIKE = 'UPDATE_DISLIKE';
export const GET_LIKES = 'GET_LIKES';
export const GET_DISLIKES = 'GET_DISLIKES';
export const GET_REACTIONS_STATE = 'GET_REACTIONS_STATE';
export const GET_REACTIONS = 'GET_REACTIONS';
export const GET_OBSERVERS = 'GET_OBSERVERS';
export const CREATE_INTERSECTION_OBSERVER = 'CREATE_INTERSECTION_OBSERVER';
export const DISCONNECT_INTERSECTION_OBSERVER = 'DISCONNECT_INTERSECTION_OBSERVER';
export const ADD_INTERSECTION_OBSERVER = 'ADD_INTERSECTION_OBSERVER';
export const SET_ISSHOWHEARTBEAT = 'SET_ISSHOWHEARTBEAT';
export const SET_ISBEFORELICKED = 'SET_ISBEFORELICKED';
// * recipes list widget
export const ADD_SIMILAR_RECIPES = 'ADD_SIMILAR_RECIPES';
export const ADD_RELATED_RECIPES = 'ADD_RELATED_RECIPES';
export const UPDATE_CURRENT_RECIPE_ID = 'UPDATE_CURRENT_RECIPE_ID';
export const GET_CURRENT_RELATED_RECIPES = 'GET_CURRENT_RELATED_RECIPES';
export const GET_CURRENT_SIMILAR_RECIPES = 'GET_CURRENT_SIMILAR_RECIPES';
export const GET_CURRENT_SIMILAR_LINK = 'GET_CURRENT_SIMILAR_LINK';
export const GET_CURRENT_RELATED_LINK = 'GET_CURRENT_RELATED_LINK';

// * visibility data
export const SET_RECIPES_VISIBILITY_DATA = 'SET_RECIPES_VISIBILITY_DATA';
// * Quick Polls
export const FETCH_QUICK_POLL = 'FETCH_QUICK_POLL';
export const FETCH_QUICK_POLL_STAT = 'FETCH_QUICK_POLL_STAT';
export const UPDATE_QUICK_POLL_ANSWER = 'UPDATE_QUICK_POLL_ANSWER';
export const SET_QUICK_POLL = 'SET_QUICK_POLL';
export const SET_QUICK_POLL_LOCATION = 'SET_QUICK_POLL_LOCATION';
export const SET_QUICK_POLL_STAT = 'SET_QUICK_POLL_STAT';
export const SET_QUICK_POLL_STATE = 'SET_QUICK_POLL_STATE';
export const SET_QUICK_POLL_SELECTED_ANSWER = 'SET_QUICK_POLL_SELECTED_ANSWER';
export const SET_CURRENT_QUICK_POLL = 'SET_CURRENT_QUICK_POLL';
export const CLEAR_QUICK_PULL_LOCATION = 'CLEAR_QUICK_PULL_LOCATION';

// * ui
export const HIDE_HEADER = 'HIDE_HEADER';
export const SHOW_HEADER = 'SHOW_HEADER';

export const HIDE_PRIMARY_MENU = 'HIDE_PRIMARY_MENU';
export const SHOW_PRIMARY_MENU = 'SHOW_PRIMARY_MENU';

export const HIDE_MODAL_DELETE_ACCOUNT = 'HIDE_MODAL_DELETE_ACCOUNT';
export const SHOW_MODAL_DELETE_ACCOUNT = 'SHOW_MODAL_DELETE_ACCOUNT';

// * Service Errors
export const SET_ARTICLE_COMMENTS_SERVICE_ERROR = 'SET_ARTICLE_COMMENTS_SERVICE_ERROR';
export const SET_RECIPE_COMMENTS_SERVICE_ERROR = 'SET_RECIPE_COMMENTS_SERVICE_ERROR';

// * Pusher
export const HANDLE_WS_EVENT = 'HANDLE_WS_EVENT';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const PUSH_BADGE = 'PUSH_BADGE';
export const FETCH_POSTS = 'FETCH_POSTS';
export const SHIFT_BADGE = 'SHIFT_BADGE';
export const REMOVE_BADGE = 'REMOVE_BADGE';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const EMPTY_NOTIFICATIONS = 'EMPTY_NOTIFICATIONS';
export const SET_NOTIFICATIONS_REQUESTED = 'SET_NOTIFICATIONS_REQUESTED';
export const SET_NOTIFICATIONS_REQUESTED_ACTION = 'SET_NOTIFICATIONS_REQUESTED_ACTION';
export const SET_READ_TO_NOTIFICATION = 'SET_READ_TO_NOTIFICATION';
export const FETCH_UNVIEWED_NOTIFICATIONS_COUNT = 'FETCH_UNVIEWED_NOTIFICATIONS_COUNT';
export const INCR_UNVIEWED_NOTIFICATIONS_COUNT = 'INCR_UNVIEWED_NOTIFICATIONS_COUNT';
export const UPDATE_UNVIEWED_NOTIFICATIONS_COUNT = 'UPDATE_UNVIEWED_NOTIFICATIONS_COUNT';
export const RESET_UNVIEWED_NOTIFICATIONS_COUNT = 'RESET_UNVIEWED_NOTIFICATIONS_COUNT';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const UPDATE_POSTS = 'UPDATE_POSTS';
export const RESET_UNVIEWED_NOTIFICATIONS_COUNT_ON_SERVER = 'RESET_UNVIEWED_NOTIFICATIONS_COUNT_ON_SERVER';
export const RESET_STORE = 'RESET_STORE';

// export const UPDATE_USER = 'UPDATE_USER';
// export const FETCH_USER = 'FETCH_USER';
