

























































import Vue, { PropType } from 'vue';

import ContextMenu from '@devhacker/shared/components/ContextMenu.vue';
import ButtonIcon from '@/components/lh-ui/ButtonIcon/ButtonIcon.vue';
import FavoriteMenu from '@/components/lh-ui/FavoriteMenu/FavoriteMenu.vue';

export default Vue.extend({
  name: 'FavoriteBlock',
  components: {
    ButtonIcon,
    ContextMenu,
    FavoriteMenu,
  },
  props: {
    user: {
      default: null,
      type: Object as PropType<IUser | null>,
    },
    favorite: {
      required: false,
      type: Object as PropType<IFavoriteItem | null>,
      default: null,
    },
    folders: {
      required: true,
      type: Object as PropType<IFolders>,
    },
    isShowFavoriteFolderName: {
      type: Boolean,
      default: false,
    },
    // цвет иконки избранного в кнопке
    color: {
      type: String as PropType<string>,
      required: false,
      default: '#969698',
    },
    // цвет иконки избранного в кнопке при наведении
    hoverColor: {
      type: String as PropType<string>,
      required: false,
      default: 'black',
    },
    // цвет текста у кнопки избранного
    colorText: {
      type: String as PropType<string>,
      required: false,
      default: '#5C5C5C',
    },
    // цвет текста у кнопки избранного при наведении
    colorTextHover: {
      type: String as PropType<string>,
      required: false,
      default: 'black',
    },
    favoriteMenuOffsets: {
      type: Object as PropType<TOffsets | null>,
      default: null,
    },
  },
  data: () => ({
    isOpenContextMenu: false,
    defaultFavoriteTitle: 'Избранное',
    addMode: 'button' as 'button' | 'input',
  }),
  computed: {
    isEnabledContextMenu (): boolean {
      return Boolean(this.folders);
    },
    handlerClass (): object {
      return {
        'lh-favorite-block__menu-handler--favorite': Boolean(this.favorite),
      };
    },
    favoriteFolder (): IFolderItem | null {
      const { favorite, folders } = this;
      let folder = null;

      if (favorite && folders.items) {
        folder = folders.items
          .find((folderItem: IFolderItem) => folderItem.id === favorite.folder_id) ?? null;
      }

      return folder;
    },
    foldersForMenu (): Array<IFolderItem> {
      return this.folders.items.map(folder => folder);
    },
    favoriteTitle (): string {
      const { favoriteFolder } = this;

      return favoriteFolder
        ? favoriteFolder.name
        : this.defaultFavoriteTitle;
      // const { favorite, folders } = this;

      // if (favorite && folders.items) {
      //   const folder_id = favorite.folder_id ? favorite.folder_id : null;
      //   const targetFolder = folders.items.find((folder) => folder.id === folder_id);

      //   return targetFolder
      //     ? targetFolder.name
      //     : this.defaultFavoriteTitle;
      // }

      // return this.defaultFavoriteTitle;
    },
    favoriteStyles (): any {
      return {
        '--base-color': this.color,
        '--hover-color': this.hoverColor,
        '--base-color-text': this.colorText,
        '--hover-color-text': this.colorTextHover,
      };
    },
    contextMenuClass (): any {
      return {
        'lh-favorite-block__menu--open': this.isOpenContextMenu,
      };
    },
  },
  watch: {
    isOpenContextMenu (newValue): void {
      this.$emit(newValue ? 'open-favorite-menu' : 'close-favorite-menu');

      if (!newValue) {
        this.addMode = 'button';
      }
    },
  },
  methods: {
    onFavoriteDelete (favorite: IFavoriteItem) {
      this.isOpenContextMenu = false;
      this.$emit('favorite-delete', favorite);
    },
    async onFavoriteCreateFolderAndAdd (nameFolder: string) {
      this.isOpenContextMenu = false;
      this.$emit('favorite-create-folder-and-add', nameFolder);
      await this.$nextTick();
      this.$emit('favorite-action');
    },
    async onFavoriteCreateFolderAndTransfer (nameFolder: string, favorite: IFavoriteItem) {
      this.isOpenContextMenu = false;
      this.$emit('favorite-create-folder-and-transfer', nameFolder, favorite);
      await this.$nextTick();
      this.$emit('favorite-action');
    },
    async onFavoriteTransferToFolder (favorite: IFavoriteItem, folder: IFolderItem) {
      this.isOpenContextMenu = false;
      this.$emit('favorite-transfer-to-folder', favorite, folder);
      await this.$nextTick();
      this.$emit('favorite-action');
    },
    async onFavoriteAddToFolder (folder: IFolderItem) {
      this.isOpenContextMenu = false;
      this.$emit('favorite-add-to-folder', folder);
      await this.$nextTick();
      this.$emit('favorite-action');
    },
    onUpdateOpenContextMenu (isOpenContextMenu: boolean): void {
      if (isOpenContextMenu && !this.user) {
        this.$emit('favorite-deferred', this.openContextMenu, this);
        return;
      }
      this.isOpenContextMenu = isOpenContextMenu;
    },
    openContextMenu (): void {
      this.isOpenContextMenu = true;
    },
    onClickContextMenuHandler (): void {
      this.onUpdateOpenContextMenu(!this.isOpenContextMenu);
    },
  },
});
